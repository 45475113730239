.create{
    margin: auto;
    margin-top: 20px;

}
.create .button-save{
    display: block;
    width: 100px;
    font-size: 1 em;
    color:#fff;
    background-color: #EE315D;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;

}
.create .button-addNote{
    display: block;
    width: 100px;
    font-size: 1 em;
    color:#EE315D;
    padding: 8px 8px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;

}

.create .select-dept .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    color: #EE315D!important;
}



.css-1w3osj-MuiFormLabel-root-MuiInputLabel-root {
    color: #EE315D !important;
    margin-top: -4px;
}
.create .select-dept .css-1w3osj-MuiFormLabel-root-MuiInputLabel-root {
    color: #EE315D !important;
}
.create .select-dept .css-9u8m51-MuiFormLabel-root-MuiInputLabel-root {
    color: #EE315D !important;
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
    color: #EE315D !important;
}
.css-i4bv87-MuiSvgIcon-root {
    color: #EE315D !important;
}
.css-9u8m51-MuiFormLabel-root-MuiInputLabel-root {
    color: #EE315D !important;
}

body {
    padding: 40px;
  }
  
  .App {
    text-align: center;
   
  }
  
  .calendarWrap .inputBox {
    
    width: auto;
    font-size: 22px;
    padding: 10px 10px 10px 10px;
    } 
  
  .calendarWrap {
    display: inline-block;
    position: relative;
  }
  
  .calendarElement {
    width: 100%;
    
    box-sizing: border-box;
    width:auto;
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    top: 75px;
    border: 1px solid #ccc;
    z-index: 999;
    border-radius: 4px;
    display: flex;
    flex-direction: column ;
  }

  .calendarElement .rdrDateDisplay {
    display: none;
    justify-content: space-between;
}




/* 
 .rdrMonths{
    background-color: yellow;
    width: auto;
    display: flex;
    flex-direction: row;
     flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center; 
}  */


.ActionStatus .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    border: 5px;
    border-color:white;
    color:white;
}

.profile{
  
     display: flex;
     flex-direction:column-reverse;
    align-items: left;
     justify-content:center; 
     margin: auto;
     width: auto;
}
.profile .text-pic{
    display: flex;
    flex-direction: column;
    align-items: left;
     justify-content:left;
    margin: auto;
    width:100%;
    padding: 10px;
  
}
.profile .profile-pic{
    display: flex;
    flex-direction: column;
     align-items: center;
     justify-content:center;
    margin: auto;
    margin-top:0px;
   
}

.profile .button-save{
    display: block;
    width: 100px;
    font-size: 1 em;
    color:#fff;
    background-color: #EE315D;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;

}
/* .css-1rv3ei8-MuiFormControl-root-MuiTextField-root {
    display: grid !important;
}*/

.css-eqtd9l-MuiPaper-root {
    margin-top: -31px  !important ;
    margin-top:0px !important;
} 

.css-1oqqzyl-MuiContainer-root {
    
    margin-top:-10px !important ;
    width: 100% !important;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: flex;
    padding-left: 0px !important;
    padding-right: 0px !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: center;
    /* align-items: flex-start; */
}
 .profile-paper .view-title{
    margin-bottom: -15px;
text-align: left;
font-size:small;
display: flex;
background-color:#f5f5f5 ;
}


.MuiSvgIcon-root {
    transform-origin: center center;
    width: 22px;
    height: 22px;
    font-size: 16px;
    outline: none;
    position: relative;
}

.Accordions .accordion{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border:none;
    text-decoration: none;
    border:none;
    background-color: #ffffff;
    box-shadow: none !important;

}

.Accordions .MuiCollapse-root {
    background-color: #f5f5f5;
}

.profile .accordionProfile{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border:none;
    text-decoration: none;
    border:none;
    background-color: #ffffff;
    box-shadow: none !important;
}
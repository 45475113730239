.DeptUser .button-save{
    display: block;
    font-size: 1 em;
    color:#fff;
    background-color: #EE315D;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;

}
.AdminUser .button-save{
    display: block;
    font-size: 1 em;
    color:#fff;
    background-color: #EE315D;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;

}

.Tabs{
margin-top:-20px;
}
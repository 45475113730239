.filter {

    margin: 0;
    margin-top: 40px;
}

.userfilter {
    margin: 0;
    margin-top: 40px;
}

.buttonMainContainer{
    width: 80%;
    height: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: flex;

}

.yeni-btn{
    border-radius: 25px !important;
    border-color:4px white !important;
    margin-right: 1% !important;
    background-color:#EE315D  !important ;
    color: white!important;
    height: 50px;
    width:200px;
    font-size:small !important ;
    cursor: pointer;
}

.yeni-btns :hover {
    background-color: #EE315D !important ;
    color:white !important;
}
.department {
    margin: 0;
    margin-top: 40px;
}

.MuiDataGrid-columnHeaders {
    position:static;
    background-color: #eceff1;
    min-height: 50px;
    max-height: 56px;
    line-height: 56px;
}

.MuiDataGrid-columnHeadersInner {
    text-align: center;
    font-size: 15px;
    text-decoration: solid;
    font-style: italic;
    
}

.MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
    font-weight: bold;
    resize: horizontal;
    overflow-x: overlay;
    overflow-y: hidden;
    border-right: 1px solid;
}

.table-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }



  .MuiDataGrid-root .MuiDataGrid-cell {
    width: 100%;
    text-overflow: initial !important;
       overflow-x: auto !important;
  }
  

.rdrDateRangePickerWrapper {
    display: flex;
    flex-wrap: wrap;
    -webkit-user-select: none;
    user-select: none;
}
.rdrDateDisplay input{
    background-color: #EE315D;
    color: white ;
}


.css-4rdffl-MuiDataGrid-panelFooter {

    visibility: hidden !important;
}

/* .css-1oovq7z-MuiDataGrid-root .MuiDataGrid-row {
  background-color: yellow; 
  
} */
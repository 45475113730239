.edittask{
    padding-bottom:40px;
}

.edittask .button-edit{
    display: block;
    width: 100px;
    font-size: 1 em;
    bottom:20px;
    color:#fff;
    font-size:small !important ;
    background-color: #EE315D;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;
    
    border-radius: 2px;
}






.edittask .button-delete{
    display: block;
    width: 100px;
    font-size: 1 em;
    color:#fff;
    padding: 8px 8px;

    background-color: #EE315D;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;
    
}
.custom-ui {
    background: deepskyblue;
    width: 400px;
    height: 200px;
    text-align: center;
    color: white;
  }  
  .custom-ui button {
    background-color: transparent;
    border: 1px solid white;
    width: 150px;
    height: 2rem;
    margin: 1rem;
    color: white;
  } 
  .edittask .TaskCompleteDate{
      visibility: hidden;
  }
.task-list .datagrid-table {
   
    margin: 0;
}

.new-user {
    position: relative;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: fixed;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #FFF;

}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}

.task-list .FormGroup {
    margin-left: 500px;
    margin-top: -75px;
}

.task-list .grid {
    margin-top: 10px;
}



.addicon {
    margin-right: 2px;
    margin-bottom: -7px;
    padding-bottom: -14px;
}




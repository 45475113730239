.navbar {
  margin: 0;
  margin-left: -40px;
  margin-right: -40px;
  /* background-color: red;  */
}
.navbar .logout-btn {
  font-size: small !important ;
  margin-left: auto;
  border-radius: 5px;
  background-color: #ee315d;
  cursor: pointer;
  text-decoration: none;
  right: -10px;
}
.navbar .noti-icon {
  margin-left: fixed;
  cursor: pointer;
  text-decoration: none;
}

.navbar .toolbar {
  box-shadow: none;
  background-color: white;
}

.left-drawer .paper :hover {
  background-color: #ee315d;
  color: white;
}

.left-drawer .paper svg {
  color: #ee315d !important;
}

.left-drawer .paper :hover svg {
  color: white !important;
}

.left-drawer .paper {
  background-color: white;
  margin-top: -50px;
}
.left-drawer {
  border: 1px;
  border-color: #121212;
  box-shadow: none;
  background-color: white;
}

.left-drawer-header
{
display: flex;
flex-direction: row;
flex-wrap: nowrap;

}

.MuiPaper-root {
  padding-right: 0 !important;
}

.left-drawer .logo {
  margin-top: -25px;
  margin-bottom: -30px;
  height: 100px;
  max-width: 160px;
  min-width: 160px;
  margin-left: 15px;
  margin-right: 20px;
}
.css-1x1qi2q {
  width: 100% !important ;
}

.basic-menu .mini-profile {
  font-size: small !important ;
  margin-left: auto;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
}

.login {
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  gap: 20px;
  height: 100vh;
  flex-wrap: wrap;
  align-content: space-between;
  width: auto;
}
.login .button-login {
    font-size:small !important ;
    display: block;
    color: #fff;
    background-color: #EE315D;
    cursor: pointer;
    text-decoration: none;
    margin: 20px auto;
    margin-left: auto;

}
.login .icon-class{
    margin-top: -60px;
    padding-bottom: 30px;
  
}
.login .container {
    align-items: center;
  justify-content:center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0px;
}

.login .arasta-logo {
    min-width:min-content;
    max-width:max-content;
   margin-right:50px ;
   margin-left:50px ;
   margin-top: 50px;
   margin-bottom:30px;
}

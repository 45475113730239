.table-display {
  width: 100%;
  margin: 0px;
  padding: 0px;
  border-collapse: collapse;
  border-spacing: 0;
}
tbody {
 
  display: table-row;
  vertical-align: middle;

  border-color: inherit;
}
.table-display thead {
  display: none;
  visibility: hidden;
}
@media screen and (min-width: 600px) {
  .table-display thead {
    display:contents;
    visibility: visible;
  }
}
.table-display tr {
    display:flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items: left; 
  border: 1px solid #ddd; 
   border-bottom: 1px solid #ddd; 
    color: blue;
    padding: 5px;
   
}
@media screen and (min-width: 600px) {
  .table-display tr { 
    border: 1px solid #ddd;
    display: table-row;
    border-bottom-width:0px;
    margin-top: 0; 
  }
  .table-display tr:nth-child(even) {
    background: #fafafa;
  }

  .table-display th {
    font-size: 14px;
  }
}
.table-display th,
.table-display td {
  padding: 10px;
  text-align: left;
}

.table-display td {
  display: block;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px dotted #ddd;
}
.table-display td:last-child {
  border-bottom: none;
}
@media screen and (min-width: 600px) {
  .table-display td {
    display: table-cell;
    text-align: left;
    font-size: 14px;
    border-bottom: none;
  }
}
.table-display td:before {
  content: attr(data-label);
  float: left;
  text-transform: uppercase;
  font-weight: bold;
 
}
@media screen and (min-width: 600px) {
  .table-display td:before {
    content: "";
    display: none;
  }
}

.css-u6yb6a-MuiTableContainer-root {
  width: 100% !important ;
  overflow-x:visible !important;
  margin-top: 40px !important;
}
.css-sor8o7-MuiTypography-root {
  color: #EE315D !important;
}
.view-class {
    margin: auto;
}

.view-class .view-title {
    text-align: left;
    font-family: inherit;
    font-size:small;
}

.view-class .view-title h1 {
    color: #3a434e;
    font-weight: 600;
    font-size: 2.6rem;
    margin-bottom: 10px;
    line-height: 1.1;
    display: block;
    font-size: 2em;
}

* {
    outline: none !important;
}

.view-class .date-user {
    text-decoration: none;
    color: #69737f;
    background-color: transparent;
    outline: none !important;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 100;
}
.action-statu {
    margin-top: 5px;

}

.time-icon {
    color: #a0a0a0;
    width: 20px;
    margin-right: 5px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    white-space: nowrap;
}
.GroupIcon{
    padding-bottom:- 6px;
    margin-bottom: -2px;
}
.AccessTimeIcon{
    padding-bottom:- 6px;
    margin-bottom: -2px;
}
.date i {
    color: #a0a0a0;
    width: 16px;
}

.user-icon {
    color: #a0a0a0;
    bottom:5px;
    width: 20px;
    margin-right: 5px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    white-space: nowrap;
   
}

.users i {
    margin-top: 10px;
    color: #a0a0a0;
    width: 18px;
    padding-inline-end: 10px;
}
.users{
    padding-bottom: 5px;
}
 
.view-class .noteEdit{
color: black !important;
}
.view-class .noteEdit :hover{
    align-items: center;
    align-content: center;
    background-color: #EE315D !important;
    color:#EE315D !important;
    }